import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';
import { capitalize } from 'lodash';

import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import {
  PricingCreativeConfigDefaultProps,
  PricingCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import { PRICING_AD } from 'site-modules/shared/components/native-ad/utils/constants';

import { withSiteServedAdWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';

import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { DfpTracking } from 'site-modules/shared/components/native-ad/utils/dfp-tracking';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';

const NATIVE_STYLE = PRICING_AD.NATIVE_STYLE;

function PricingTrimAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  position,
  creativeConfig,
  mobile,
  wrapperClassName,
  ivtClassName,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    creativeConfigData: {
      logo,
      headline,
      make,
      linkDisplayUrl,
      cta,
      disclaimerCopy,
      isConquest,
      lineItemId,
      siteServedCreativeId,
      target,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const headlineWords = isConquest && headline?.split(' ');
  const headlineDisplay =
    !!headlineWords?.length && [headlineWords[0], capitalize(make), ...headlineWords.slice(1)].join(' ');

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, headlineDisplay || cta);
    },
    [adLoadTrackingListener, cta, headlineDisplay]
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';

  return (
    <Card
      className={classnames('pricing-trim-ad pos-r', wrapperClassName, ivtClassName)}
      data-tracking-parent={NATIVE_STYLE}
      data-viewability={`${NATIVE_STYLE}-${position}`}
      innerRef={adRef}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={NATIVE_STYLE}
        position={position}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        href={ctaURL}
        target={ctaTarget}
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
        className="site-served-ad-link d-block no-focus h-100 text-center text-decoration-none overflow-hidden"
        style={{ padding: '40px 22px 32px' }}
      >
        <div className="disclaimer text-gray-darker" style={{ position: 'absolute', right: 5, top: 8 }}>
          Sponsored
        </div>
        {isConquest ? (
          <ConquestRibbon style={{ position: 'absolute', left: 0, top: 8, height: '20px' }} />
        ) : (
          <img
            className="logo"
            style={{ position: 'absolute', left: 8, top: 4, maxHeight: '24px' }}
            alt=""
            src={logo}
            loading="lazy"
          />
        )}
        {!!headlineDisplay && (
          <ContentFragment classes="heading-5 text-gray-darkeset mb-0_5">{headlineDisplay}</ContentFragment>
        )}
        <div className="link text-blue-50 size-16">
          {cta} at <CtaButtonText text={linkDisplayUrl} />
        </div>
      </a>
      {disclaimerCopy && <Disclaimer disclaimerText={disclaimerCopy} buttonStyle={{ bottom: 0 }} />}
    </Card>
  );
}

PricingTrimAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: PricingCreativeConfigPropTypes,
  wrapperClassName: PropTypes.string,
};

PricingTrimAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: PricingCreativeConfigDefaultProps,
  wrapperClassName: null,
};

const PricingTrimAddWrapper = withSiteServedAdWrapper(siteServedAdTrackingWrapper(PricingTrimAdUI));

export function PricingTrimAd(props) {
  return <PricingTrimAddWrapper {...props} adType={PRICING_AD} />;
}
